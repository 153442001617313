* {
  font-family: "Nunito", sans-serif;
}

#root {
  background-color: #00080E;
}

.Composer-input {
    font-size: 18px !important;
    padding: 0.6rem .75rem !important;
  }
  
  
  .Bubble.text > p {
    font-size: 17px !important;
    text-align: left !important;
  }
  
  .Composer {
    display: none !important;
  }

  .audio-recorder.recording {
    max-width: 230px !important;
  }

  .audio-recorder {
    width: 45px !important;
    height: 45px !important;
  }

  .audio-recorder-mic {
    height: 20px !important;
  }
  


  .Avatar img {
    height: 3rem !important;
    width: 3rem !important;
  }


  .Message.left .Bubble {
    margin-right: 1.5rem !important;
  }



  .custom-chat {
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 2px 2px 5px grey;
    padding: 10px;
 }


[data-testid="ar_cancel"] {
  display: none !important;
 }


pre {
  border-radius: 20px;
  padding: 15px;
  line-height:  1.7;
  background-color: rgba(0, 0, 0, 0.937);
  color: white;
  white-space: pre;
  box-sizing: border-box;
  min-width: 2.5rem;
  overflow-wrap:break-word;
  white-space:pre-wrap;

}

pre {
  color: tomato !important;
}
pre code {
  color: mediumspringgreen !important;
}


.ChatApp {
  background-color: #00080E;
}

select option {
  color: purple !important;
}